body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fff !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4d4e8;
  border-radius: 10px;
}

.accessibility {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  margin-top: -21px;
  margin-bottom: 33px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:focus {
  left: 10px;
  top: auto;
  width: 10%;
  margin-top: -21px;
  margin-bottom: 33px;
  height: auto;
  overflow: auto;
  margin: 0 35%;
  font-size: 15px;
  text-align: center;
  z-index: 999;
}
#durationWrapper:first-child .remove-icon {
  display: none;
}
