.App {
  text-align: center;
  background-color: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.loadingcss { 
  height: 40px;
  top: 50%; 
  left: 50%;
  margin-top: 18px;
}
.loadingcss span { 
  font-family: -apple-system, BlinkMacSystemFont, 
      "Segoe UI", Roboto,Oxygen, Ubuntu, Cantarell, 
      "Open Sans", "Helvetica Neue", sans-serif; 
  font-size: 19px; 
  color: rgb(52, 130, 202); 
  display: inline-block; 
  transition: all 0.5s; 
  animation: animate 2s infinite; 
}
.loadingcss span:nth-child(1) { 
  animation-delay: 0.2s; 
} 
.loadingcss span:nth-child(2) { 
  animation-delay: 0.2s; 
} 
.loadingcss span:nth-child(3) { 
  animation-delay: 0.2s; 
} 
.loadingcss span:nth-child(4) { 
  animation-delay: 0.2s; 
} 
.loadingcss span:nth-child(5) { 
  animation-delay: 0.2s; 
}
@keyframes animate { 
  0% { 
      color: rgb(52, 130, 202); 
      transform: translateY(0); 
      margin-left: 0; 
  } 
  25% { 
      color: rgb(52, 130, 202); 
      transform: translateY(-15px); 
      margin-left: 5px;
  } 
  100% { 
      color: rgb(52, 130, 202); 
      transform: translateY(0); 
      margin-left: 0; 
  } 
}