body .indicatorArrowAnimation {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 263px;
  margin-left: 15px;
  transform: rotate(-45deg);
}
body .indicatorArrowAnimation span {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  animation: animate 2s linear infinite;
}
body .indicatorArrowAnimation span:nth-child(1) {
  top: -30px;
  left: -30px;
  animation-delay: 0s;
}
body .indicatorArrowAnimation span:nth-child(2) {
  top: -15px;
  left: -15px;
  animation-delay: 0.2s;
}
body .indicatorArrowAnimation span:nth-child(3) {
  top: 0;
  left: 0;
  animation-delay: 0.4s;
}
body .indicatorArrowAnimation span:nth-child(4) {
  top: 15px;
  left: 15px;
  animation-delay: 0.6s;
}
body .indicatorArrowAnimation span:nth-child(5) {
  top: 30px;
  left: 30px;
  animation-delay: 0.8s;
}
@keyframes animate {
  0% {
    opacity: 0;
    border-color: #1E90FF;
    transform: translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    border-color: #1E90FF;
    transform: translate(20px, 20px);
  }
}
